import { IconBrandAppgallery } from "@tabler/icons-react";

const CartIcon = ({ cartItemsCount }) => {
    return (
        <div className="nav-cart">
            <IconBrandAppgallery />
            <span className="nav-cart-count">
                {cartItemsCount ? cartItemsCount : 0}
            </span>
        </div>
    );
};

export default CartIcon;
