import { IconCategory, IconShirt, IconSunglasses } from "@tabler/icons-react";
import TShirtFigure from "../images/dfeac3fe-247e-4fb9-92e8-c6515c4f573a.jpeg";
import MugsCupsFigure from "../images/39df2984-ca58-4a05-9948-617376a884e9.jpeg";
import AccessoriesFigure from "../images/b7f3f8f1-6dd3-470a-8998-2579a69ec8dd.jpeg";

const ProductsSection = () => {
    return (
        <div className="main-container-inner">
            <Categories />

            <Products />
        </div>
    );
};

const Products = () => {
    return (
        <div className="main-container-inner-mainpage-section">
            <h1>
                <IconSunglasses />
                Products
            </h1>
            <div className="main-container-inner-mainpage-section-cards products">
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card products-product">
                    <div className="products-product-image">
                        <img src="https://png.pngtree.com/png-clipart/20230930/original/pngtree-white-t-shirt-mockup-realistic-t-shirt-png-image_13020297.png" />
                    </div>
                    <div className="products-product-info">
                        <div className="products-product-info-title">
                            High Quality Pirate Skull testing besting resting
                            jesting
                        </div>
                        <div className="products-product-info-price">$9.99</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Categories = () => {
    return (
        <div className="main-container-inner-mainpage-section">
            <h1>
                <IconCategory />
                Categories
            </h1>
            <div className="main-container-inner-mainpage-section-cards">
                <div className="main-container-inner-mainpage-section-cards-card apparels">
                    <figure className="apparels-figure">
                        <img src={TShirtFigure} alt="Trulli" />
                        <figcaption>
                            <span>
                                {/* <IconShirt /> */}
                                T-Shirts
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="main-container-inner-mainpage-section-cards-card accessories">
                    <div className="main-container-inner-mainpage-section-cards-card-mini accessories-section">
                        <figure className="accessories-figure">
                            <img src={MugsCupsFigure} alt="Trulli" />
                            <figcaption>
                                <span>Cups &amp; Mugs</span>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="main-container-inner-mainpage-section-cards-card-mini accessories-section">
                        <figure className="accessories-figure">
                            <img src={AccessoriesFigure} alt="Trulli" />
                            <figcaption>
                                <span>Accessories</span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductsSection;
