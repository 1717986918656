import "./styles/main.css";
import Header from "./components/Header";
import ProductsSection from "./pages/Products";
import { IconLink } from "@tabler/icons-react";

function App() {
    return (
        <div className="main-container">
            <Header />

            <ProductsSection />

            <div className="main-container-inner footer">
                <div className="main-container-inner-mainpage-section">
                    <h1>
                        <IconLink />
                        External Links
                    </h1>
                    <div className="main-container-inner-mainpage-section-cards">
                        <ol className="main-container-inner-mainpage-section-cards-card external-links">
                            <li>
                                <a href="#">RuLo Studio</a>
                            </li>
                        </ol>
                    </div>
                </div>

                <h3>
                    Copyright &copy; 2024,{" "}
                    <a
                        href="https://prashant.me"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Prashant Shrestha
                    </a>
                    .<br />
                    Trademark RL Prints&trade;, 2024.
                </h3>
            </div>
        </div>
    );
}

export default App;
