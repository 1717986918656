// import logo from "../images/rl-prints-logo.png";
import logo from "../images/RL - Prints - Block - No Prints.png";
import {
    IconBrandInstagram,
    IconCategory2,
    IconHanger,
    IconRuler,
    IconUser,
} from "@tabler/icons-react";
import CartIcon from "./CartIcon";

const Header = ({ cart }) => {
    return (
        <div className="inner-navigation">
            <div className="inner-navigation-part">
                <a href="#" className="logo" title="#">
                    <img src={logo} alt="" />
                </a>
            </div>
            <div className="inner-navigation-part">
                <a href="#" className="menu" title="#">
                    <IconRuler />
                    Sizer
                </a>
                <a href="#" className="menu" title="#">
                    <IconBrandInstagram />
                    Social
                </a>
                <a href="#" className="menu" title="#">
                    <IconHanger />
                    Store
                </a>
                <a href="#" className="cart" title="#">
                    <CartIcon cartItemsCount={cart ? cart.length : 0} />
                </a>
            </div>
        </div>
    );
};

export default Header;
